import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncEffect } from 'use-async-effect';
import { BrowserRouter as Router } from 'react-router-dom';
import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import { BrandedSpinner, BrandedSpinnerSize } from '@evacenter/eden';
import {
  useModal,
  Modals,
  getCookie,
  removeCookie,
  I18N_LANGUAGE_KEY,
  ACCESS_TOKEN_KEY,
  getRefreshInfo,
} from '@eva-pacs/core';
import ErrorBoundary from '~/utils/ErrorBoundary';
import { useAuthService, usePostMessageListener } from '~/hooks';
import { renderRoutes } from './routes';
import initCornerstoneTools from '~/utils/initCornerstoneTools';
import { CrossPacsActionType } from './constants';
import { useMeasurementStore, useViewportStore } from './store';
import {
  getDecryptedCredentials,
  getEncryptedCredentialsPractitioner,
  getEncryptedCredentialsVisitant,
  hasEncryptedCredentials,
} from './utils/locationHelpers';
import { parseI18nLanguage } from './utils/parseI18nLanguage';
import { HeadService } from './hooks';
import { useLanguage } from './hooks/useLanguage';
import { useRecoverSession } from './hooks/auth/recoverSession';
import { useWebspellchecker } from './hooks/useWebspellchecker';
import { useLoggerSession } from './hooks/useLoggerSession';
import { ToolName } from './constants/toolName';
import { useGlobalError } from './hooks/useGlobalError';

/**
 * App main entry point
 * @author Mario Lugo<mariolugo23@gmail.com>
 * Created at 2021-04-05
 */
const App: React.FC = () => {
  useGlobalError();
  useLoggerSession();
  const { addGoogleTagManager } = HeadService();
  const { t } = useTranslation();
  const { setCurrentModal } = useModal();
  const [isLoaded, setIsLoaded] = useState(false);
  const { logInByRefreshToken, loginVisitant, logInByUserData } = useAuthService();
  const { validateSession } = useRecoverSession();
  const { currentLanguage, changeLanguage } = useLanguage();

  const { toggleHotkeys } = useViewportStore();

  const setAnnotationText = useMeasurementStore((store) => store.setAnnotationText);

  usePostMessageListener({
    actionType: CrossPacsActionType.UPDATE_LANGUAGE,
    onMessageReceived: (eventData) => changeLanguage(eventData?.payload['language'] as string),
  });

  const cleanAnnotation = (eventData) => {
    const toolState = cornerstoneTools.globalImageIdSpecificToolStateManager.saveToolState();
    const annotations = toolState[eventData.image.imageId][ToolName.ArrowAnnotate].data;
    annotations.splice(-1);
    toolState[eventData.image.imageId][ToolName.ArrowAnnotate].data = annotations;
    cornerstone.updateImage(eventData.element);
  };

  const logInByEncryptedCredentials = async () => {
    // Get credentials strings. Credentials might be from a visitant or a practitioner
    const visitantCredentials = getEncryptedCredentialsVisitant();
    const practitionerCredentials = getEncryptedCredentialsPractitioner();

    // Try visitant credentials first, if detected won't try the other type
    if (visitantCredentials) {
      const { user, password } = getDecryptedCredentials(visitantCredentials);
      await loginVisitant(user, password);
    } else if (practitionerCredentials) {
      const { user, password } = getDecryptedCredentials(practitionerCredentials);
      await logInByUserData(user, password);
    }
  };

  useAsyncEffect(async () => {
    validateSession();
    const { isVisitant, refreshToken } = getRefreshInfo();
    const urlHasEncryptedCredentials = hasEncryptedCredentials();

    if (urlHasEncryptedCredentials) await logInByEncryptedCredentials();
    else if (refreshToken) await logInByRefreshToken(refreshToken, isVisitant);
    else removeCookie(ACCESS_TOKEN_KEY);

    setIsLoaded(true);

    const handleCancel = (event) => {
      setCurrentModal(null);
      toggleHotkeys();
      cleanAnnotation(event);
    };

    const handleSuccess = (callback, value) => {
      callback(value);
      setAnnotationText(value);
      toggleHotkeys();
      setCurrentModal(null);
    };

    const handleArrowAnnotationCallback = (callback, event) => {
      toggleHotkeys();
      setCurrentModal({
        name: Modals.NoteModal,
        props: {
          title: t('general.modal.addNote'),
          hasCloseButton: false,
          onCancel: (): void => handleCancel(event),
          onAccept: (value): void => handleSuccess(callback, value),
        },
      });
    };

    /**
     * Initialize cornerstone
     */
    initCornerstoneTools(handleArrowAnnotationCallback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Configure the browser language to match with the supported ones by the app
  useEffect(() => {
    const i18nParsedLanguage = parseI18nLanguage(getCookie(I18N_LANGUAGE_KEY) || currentLanguage);
    changeLanguage(i18nParsedLanguage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  addGoogleTagManager();
  useWebspellchecker();

  if (!isLoaded) return <BrandedSpinner isFullScreen size={BrandedSpinnerSize.xxl} />;

  return (
    <>
      <ErrorBoundary context="App">
        <Router>{renderRoutes()}</Router>
      </ErrorBoundary>
    </>
  );
};

export default App;
