import React, { useEffect, useState } from 'react';
import {
  DownloadQuery,
  useDownloadLazyQuery,
  CreateStudiesCsvMutation,
  CreateWorklistCsvMutation,
  useCreateStudiesCsvMutation,
  useCreateWorklistCsvMutation,
} from '@eva-pacs/client';
import cn from 'classnames';
import { EmptyState } from '@eva-pacs/ui';
import { useTranslation } from 'react-i18next';
import { handleProgrammaticDownload, useInterval } from '@eva-pacs/core';

import { useErrorHandler } from '~/utils/appHelpers';
import { RETRY_CSV_DOWNLOAD_TIME, ZIP_IMAGE_PATH } from '~/constants';
import { WorklistTabNames } from '~/src/types/StudyList';

export interface DownloadCsvProps {
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * Handle close current modal
   */
  onClose: () => void;

  /**
   * Active tab in study list
   */
  activeTab?: WorklistTabNames;
}

/**
 * Use this component in order to show waiting state for csv generation and download modal
 * @author Ricardo Aguirre Nava<ricardo.a.nava@gmail.com>
 * Created at 2021-01-24
 */
export const DownloadCsv: React.FC<DownloadCsvProps> = ({ className, onClose, activeTab }) => {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();

  const modalClass = cn(className, 'max-w-sm');

  const [downloadID, setDownloadID] = useState<string>('');

  const [getDownload] = useDownloadLazyQuery({
    onCompleted: async ({ download }: DownloadQuery) => {
      if (download?.fileUrl) {
        try {
          const data = await fetch(download?.fileUrl);
          const blob = await data.blob();
          const url = window.URL.createObjectURL(blob);
          handleProgrammaticDownload(url, {
            fileName: activeTab === WorklistTabNames.WORKLIST ? `workList.csv` : `studyList.csv`,
          });
        } catch (error) {
          handleError({ logMessage: error });
        }
        onClose();
        setDownloadID('');
      }
    },
    fetchPolicy: 'network-only',
  });

  const [createStudiesCsv] = useCreateStudiesCsvMutation({
    onCompleted: ({ createStudiesCsv: { download, errors } }: CreateStudiesCsvMutation) => {
      if (download?.id) {
        getDownload({
          variables: {
            id: download?.id,
          },
        });
        setDownloadID(download?.id);
      } else if (errors) {
        handleError({
          logMessage: errors,
        });
      }
    },
  });

  const [createWorklistCsv] = useCreateWorklistCsvMutation({
    onCompleted: ({ createWorklistCsv: { download, errors } }: CreateWorklistCsvMutation) => {
      if (download?.id) {
        getDownload({
          variables: {
            id: download?.id,
          },
        });
        setDownloadID(download?.id);
      } else if (errors) {
        handleError({
          logMessage: errors,
        });
      }
    },
  });

  useEffect(() => {
    if (activeTab === WorklistTabNames.WORKLIST) createWorklistCsv();
    else createStudiesCsv();
    // eslint-disable-next-line
  }, [activeTab]);

  useInterval(() => {
    if (downloadID) {
      getDownload({
        variables: {
          id: downloadID,
        },
      });
    }
  }, RETRY_CSV_DOWNLOAD_TIME);

  return (
    <div className={modalClass}>
      <EmptyState
        imageSrc={ZIP_IMAGE_PATH}
        title={t('general.downloadDicom.caption')}
        description={t('general.downloadDicom.description')}
      />
    </div>
  );
};
