import { UserType } from '@eva-pacs/client';
import create, { GetState, SetState } from 'zustand';
import produce from 'immer';

import {
  ACCESS_TOKEN_KEY,
  ORGANIZATION_ID_KEY,
  REFRESH_TOKEN_KEY,
  setCookie,
  removeCookie,
  VISITANT_KEY,
} from '@eva-pacs/core';

const immer = (config) => (set, get) => config((fn) => set(produce(fn)), get);

/**
 * Session Store
 */
export interface Account {
  organizationId: string;
  organizationName: string;
  practitionerId: string;
  userInfo: {
    fullName: string;
    name: string;
    lastName: string;
    phone: string;
    phoneCode: string;
    gender: string;
  };
  hasPendingPayment: boolean;
}

export interface SessionStore {
  token: string;
  refreshToken: string;
  user: UserType | null;
  accounts: Array<Account>;
  currentOrganizationId: string;
  setToken: (token: string, visitant?: boolean) => void;
  setRefreshToken: (refreshToken: string, visitant?: boolean) => void;
  setUser: (user: UserType | null) => void;
  setAccounts: (accounts: Array<Account>) => void;
  setCurrentOrganizationId: (organizationId: string, visitant?: boolean) => void;
  isAuthenticated: () => boolean;
  getCurrentAccount: () => Account;
}

const cookieConfig = { path: '/', domain: process.env['REACT_APP_ROOT_DOMAIN'] };

const sessionStore = (set: SetState<SessionStore>, get: GetState<SessionStore>): SessionStore => ({
  token: '',
  refreshToken: '',
  currentOrganizationId: '',
  user: null,
  accounts: [],
  setToken: (token, visitant = false) => {
    return set((state) => {
      state.token = token;
      const visitantToken = `${VISITANT_KEY}_${ACCESS_TOKEN_KEY}`;

      if (!token) {
        removeCookie(ACCESS_TOKEN_KEY);
        removeCookie(visitantToken);
        return;
      }
      if (visitant) setCookie(visitantToken, token, cookieConfig);
      else setCookie(ACCESS_TOKEN_KEY, token, cookieConfig);
    });
  },
  setRefreshToken: (refreshToken, visitant = false) => {
    return set((state) => {
      state.refreshToken = refreshToken;
      const visitantRefreshToken = `${VISITANT_KEY}_${REFRESH_TOKEN_KEY}`;

      if (!refreshToken) {
        removeCookie(REFRESH_TOKEN_KEY);
        removeCookie(visitantRefreshToken);
        return;
      }
      if (visitant) setCookie(visitantRefreshToken, refreshToken, cookieConfig);
      else setCookie(REFRESH_TOKEN_KEY, refreshToken, cookieConfig);
    });
  },
  setAccounts: (accounts) => {
    return set((state) => {
      state.accounts = [...accounts];
    });
  },
  setCurrentOrganizationId: (organizationId, visitant = false) => {
    return set((state) => {
      state.currentOrganizationId = organizationId;
      const visitantOrganizationId = `${VISITANT_KEY}_${ORGANIZATION_ID_KEY}`;

      if (!organizationId) {
        removeCookie(ORGANIZATION_ID_KEY);
        removeCookie(visitantOrganizationId);
        return;
      }
      if (visitant) setCookie(visitantOrganizationId, organizationId, cookieConfig);
      else setCookie(ORGANIZATION_ID_KEY, organizationId, cookieConfig);
    });
  },
  setUser: (user) => {
    return set((state) => {
      state.user = user ? Object.assign({}, user) : null;
    });
  },
  isAuthenticated: () => Boolean(get().token),
  getCurrentAccount: () => get().accounts.find((account) => account.organizationId === get().currentOrganizationId)!,
});

export const useSessionStore = create<SessionStore>(immer(sessionStore));
